import { NextPageCustom, UserError } from '@pepper/client';
import { withStaticProps } from '@pepper/client/ssr';

const NotFound: NextPageCustom = () => {
  return <UserError statusCode={404} />;
};

NotFound.config = (_props, t) => {
  return {
    title: t('common:errors.404'),
    gtm: {
      category: 'error',
      pageTitle: 'Page not found',
    },
  };
};

export const getStaticProps = withStaticProps();

export default NotFound;
